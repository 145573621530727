import MainLayout from '@/layouts/MainLayout';
import { graphql, navigate } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import EmptyBlockSVG from '@/icons/empty-block.inline.svg';
import { PrimaryButton, OutlineButton } from '@/components/buttons';

const NotFoundPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <MainLayout>
      <section className="full-page flex flex-col items-center justify-center lg:justify-between lg:flex-row-reverse px-4 py-28 lg:py-32 md:px-6 lg:px-16 xl:px-28 2xl:px-0 max-w-7xl mx-auto">
        <div className="relative w-full lg:w-2/5 lg:pl-6">
          <div className="absolute z-0 -top-20 right-0 bg-neutral-100 rounded-full w-52 h-52 sm:w-72 sm:h-72 lg:w-52 lg:h-52  md:right-24 lg:right-0" />
          <EmptyBlockSVG className="relative z-10 w-full h-full md:w-3/5 lg:w-full md:h-auto mx-auto" />
        </div>
        <div className="w-full lg:w-3/5 flex flex-col items-center justify-center mt-10 lg:items-start text-center lg:text-left lg:pr-6">
          <h1 className="text-4xl lg:text-5xl font-semibold text-primary-main whitespace-normal lg:whitespace-pre-line">
            {t('pages.notfound.section-1.header-1')}
          </h1>
          <p className="text-xl lg:text-2xl text-primary-main mt-4">
            {t('pages.notfound.section-1.desc-1')}
          </p>
          <div className="flex flex-row mt-6 w-full max-w-lg">
            <Link to="/" className="w-1/2 pr-3">
              <PrimaryButton
                title={t('components.buttons.go-to-home')}
                className="w-full justify-center"
              />
            </Link>
            <OutlineButton
              title={t('components.buttons.back')}
              className="!border-primary-main !text-primary-main !ring-primary-focus w-1/2 ml-3 justify-center"
              onClick={() => navigate(-1)}
            />
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query NotFoundPage($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { eq: "translation" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
